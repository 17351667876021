




































































































































































































import { ConfirmModal, InputErrorIcon, JsonEditor } from '@/app/components';
import { useApolloHarvesterLogin } from '@/modules/apollo/composable';
import {
    ApiHarvesterAuthenticationConfiguration,
    ApiHarvesterAuthenticationTokenType,
    ApiHarvesterHeader,
} from '@/modules/apollo/types';
import { PropType, Ref, computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { ShieldCheckIcon, LockClosedIcon, PencilAltIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { isEmpty, isNil } from 'ramda';

type Authentication = ApiHarvesterAuthenticationConfiguration & {
    tokenType: ApiHarvesterAuthenticationTokenType | 'no' | null;
};

export default defineComponent({
    name: 'APIHarvesterAuthentication',
    model: { prop: 'auth', event: 'changed' },
    props: {
        auth: { type: Object as PropType<ApiHarvesterAuthenticationConfiguration>, required: true },
        disabled: { type: Boolean, default: false },
        hasChanges: { type: Boolean, default: false },
        isOwner: { type: Boolean, required: true },
        loginResponse: { type: [Object, Array] as PropType<Record<string, any> | Record<string, any>[]> },
    },
    components: {
        ValidationProvider,
        ConfirmModal,
        JsonEditor,
        InputErrorIcon,
        PencilAltIcon,
        ShieldCheckIcon,
        LockClosedIcon,
        ExclamationIcon,
    },
    setup(props, { emit }) {
        const showConfirmIgnoreCertificatesModalCallback: Ref<Function | undefined> = ref<Function>();
        const showConfirmEditLoginModal = ref<boolean>(false);
        const inResetMode = ref<boolean>(false);
        const loginResponseKeys: Ref<string[]> = computed(() =>
            props.loginResponse ? Object.keys(props.loginResponse) : [],
        );
        const invalidLoginBody = ref<boolean>(false);
        const isLoginTested = computed(
            () => (!isNil(props.loginResponse) && !isEmpty(props.loginResponse)) || props.disabled,
        );

        const initializeAuthentication = (
            newAuthentication: ApiHarvesterAuthenticationConfiguration,
        ): Authentication => {
            const method = newAuthentication.method || 'no';
            return {
                ...newAuthentication,
                method,
                tokenType: newAuthentication.tokenType,
                headers: newAuthentication.headers || [],
                ignoreCertificates: newAuthentication.ignoreCertificates || false,
                url:
                    ['no', 'bearer'].includes(method) ||
                    isNil(newAuthentication.url) ||
                    isEmpty(newAuthentication.url.trim)
                        ? null
                        : newAuthentication.url,
            } as Authentication;
        };
        const authentication: Ref<Authentication> = ref<Authentication>(initializeAuthentication(props.auth));

        const authenticationMethods = ['no', 'bearer', 'keycloak', 'custom'];

        const loginAlert: any = reactive({
            isError: true,
            title: null,
            body: null,
            showIgnore: false,
        });

        const inVault = (str: string): boolean => {
            if (str) {
                return str.startsWith('vault://');
            }
            return false;
        };

        const { login, loading } = useApolloHarvesterLogin(authentication);

        const testLogin = () => {
            loginAlert.title = null;
            loginAlert.body = null;
            loginAlert.isError = false;
            loginAlert.showIgnore = false;
            login()
                .then((data: any) => {
                    loginAlert.title = 'Successful Login';
                    loginAlert.body = '';
                    loginAlert.isError = false;
                    loginAlert.showIgnore = false;
                    inResetMode.value = false;
                    emit('test-login', data);
                })
                .catch((e: { title: string; body: string | null; showIgnore: boolean; invalidLoginBody: boolean }) => {
                    invalidLoginBody.value = e.invalidLoginBody;
                    loginAlert.title = e.title;
                    loginAlert.body = e.body;
                    loginAlert.isError = true;
                    loginAlert.showIgnore = e.showIgnore;
                });
        };

        const confirmIgnoreCertificatesModal = (callback: Function) => {
            showConfirmIgnoreCertificatesModalCallback.value = callback;
        };

        const cancelIgnoreCertificates = () => {
            authentication.value.ignoreCertificates = false;
            showConfirmIgnoreCertificatesModalCallback.value = undefined;
        };

        const confirmIgnoreCertificates = () => {
            if (showConfirmIgnoreCertificatesModalCallback.value instanceof Function)
                showConfirmIgnoreCertificatesModalCallback.value();
            showConfirmIgnoreCertificatesModalCallback.value = undefined;
        };

        const setAuthenticationHeader = () => {
            if (authentication.value.accessToken === null || authentication.value.accessToken.length === 0) {
                for (let i = 0; i < authentication.value.headers.length; i += 1) {
                    if (authentication.value.headers[i].key === 'Authorization') {
                        authentication.value.headers.splice(i, 1);
                        break;
                    }
                }
                return;
            }
            const authenticationHeader = authentication.value.headers.find(
                (header: ApiHarvesterHeader) => header.key === 'Authorization',
            );

            if (authenticationHeader === undefined) {
                emit('add-header', {
                    key: 'Authorization',
                    value: `${authentication.value.tokenType} ${authentication.value.accessToken}`,
                    sensitive: true,
                });
                emit('add-header-tag', {
                    value: '',
                    tags: [],
                });
            } else {
                authenticationHeader.value = `${authentication.value.tokenType} ${authentication.value.accessToken}`;
            }
        };

        const editLogin = () => {
            loginAlert.title = null;
            loginAlert.body = null;
            loginAlert.showIgnore = false;
            invalidLoginBody.value = false;
            emit('clear-login-response');
        };

        const resetAuthenticationMethod = (event: any) => {
            const authMethod = event.target.value;

            loginAlert.title = null;
            loginAlert.body = null;
            loginAlert.showIgnore = false;
            invalidLoginBody.value = false;
            authentication.value.accessToken = null;
            authentication.value.tokenType = null;
            authentication.value.url = null;
            authentication.value.payload = null;
            authentication.value.headers = [];
            emit('clear-login-response');

            if (authMethod === 'bearer') {
                authentication.value.tokenType = 'Bearer';
            } else if (authMethod === 'keycloak') {
                authentication.value.payload = JSON.stringify(
                    {
                        client_secret: '<ENTER_YOUR_CLIENT_SECRET>',
                        client_id: '<ENTER_YOUR_CLIENT_ID>',
                        username: '<ENTER_YOUR_USERNAME>',
                        password: '<ENTER_YOUR_PASSWORD>',
                        grant_type: 'password',
                    },
                    null,
                    2,
                );
            }
        };

        watch(
            () => authentication.value,
            (newAuthentication: Authentication) => emit('changed', newAuthentication),
            { deep: true, immediate: true },
        );

        return {
            authentication,
            loginAlert,
            loginResponseKeys,
            showConfirmIgnoreCertificatesModalCallback,
            invalidLoginBody,
            loading,
            showConfirmEditLoginModal,
            testLogin,
            inVault,
            setAuthenticationHeader,
            confirmIgnoreCertificatesModal,
            cancelIgnoreCertificates,
            confirmIgnoreCertificates,
            resetAuthenticationMethod,
            authenticationMethods,
            isLoginTested,
            editLogin,
            inResetMode,
        };
    },
});
