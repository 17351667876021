



















































import { TwButton } from '@/app/components';
import { useSchedule } from '@/app/composable';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { HarvesterBlockId, RetrievalType, SQLRetrievalMethod } from '../../../constants';
import { SQLHarvesterConfiguration } from '../../../types';
import { RetrievalSettings, Schedules, DataRetrievalDetails } from '../common';
import SQLConnectionDetails from './SQLConnectionDetails.vue';
import SQLDatabaseDetails from './SQLDatabaseDetails.vue';

export default defineComponent({
    name: 'SetupSQLHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<SQLHarvesterConfiguration>,
            required: true,
        },
        pipelineFinalized: {
            type: Boolean,
            default: false,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        scheduleDetailsDefined: {
            type: Boolean,
            default: false,
        },
        invalidQuery: {
            type: Boolean,
            default: false,
        },
        containsModificationTerms: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isRunning: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        hasCompleted: {
            type: Boolean,
            default: false,
        },
        inDraftStatus: {
            type: Boolean,
            default: false,
        },
        pipelineId: {
            type: String,
            default: '',
        },
        hasAnonymisation: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RetrievalSettings,
        DataRetrievalDetails,
        Schedules,
        SQLDatabaseDetails,
        SQLConnectionDetails,
        ValidationObserver,
        TwButton,
        ChevronRightIcon,
    },
    setup(props, { emit, root }) {
        const { isValidSchedule } = useSchedule();

        const sqlValidationRef = ref<any>();

        const sqlConfiguration: Ref<SQLHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: SQLHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const validateAndProceed = async () => {
            if (!sqlValidationRef.value) return;

            const valid = await sqlValidationRef.value.validate();
            if (!valid) return;

            const invalidSchedules = props.schedules.filter(
                (schedule: ScheduleType) => !isValidSchedule(schedule, retrieveOnce.value),
            );

            if (!props.pipelineFinalized && invalidSchedules.length) {
                (root as any).$toastr.e(
                    'One or more schedules are in the past or have no valid executions between start and end date. Please update them accordingly.',
                    'Invalid Schedules!',
                );
                return;
            }

            emit('next-tab');
        };

        const retrieveOnce = computed(() => sqlConfiguration.value.retrieval.type === RetrievalType.Once);

        const disableNext = computed(
            () =>
                !props.scheduleDetailsDefined ||
                (sqlConfiguration.value.method === SQLRetrievalMethod.Query &&
                    (props.containsModificationTerms || props.invalidQuery)),
        );

        const readOnlyMessage = computed(() => {
            if (!props.isOwner) return 'Only the owner of the pipeline is allowed to update its schedules';
            if (retrieveOnce.value) {
                if (props.hasCompleted) return 'You cannot update a schedule that has already been executed';
                if (props.isRunning) return 'You cannot update a schedule that is currently being executed';
            }
            return null;
        });

        const queryOrTableChanged = () => {
            emit('clear-processed-sample');
            sqlConfiguration.value.identifierColumns = [];
        };

        const clearProcessedSample = () => {
            emit('clear-processed-sample');
        };

        return {
            sqlConfiguration,
            HarvesterBlockId,
            disableNext,
            validateAndProceed,
            readOnlyMessage,
            queryOrTableChanged,
            retrieveOnce,
            sqlValidationRef,
            clearProcessedSample,
        };
    },
});
