































import { ApiHarvesterConfiguration, ApolloTask, TaskStep } from '@/modules/apollo/types';
import { PropType, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload } from '@/modules/apollo/composable';
import { TaskStatus } from '@/modules/apollo/constants';
import { is } from 'ramda';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleType } from '@/modules/workflow-designer/types';

export default defineComponent({
    name: 'APIHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<ApiHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        ApolloTaskShell,
    },
    setup(props, { root }) {
        const apolloTaskShellRef = ref<any>(null);
        const currentStep = ref<number>(0);
        const loadingHarvester = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const loadingSampleRun = ref<boolean>(false);

        const task = ref<ApolloTask<ApiHarvesterConfiguration>>(props.task);
        const {
            loading: loadingTask,
            isFinalized,
            inDraftStatus,
            save,
            finalize,
            shouldClearHarvesterProcessedSample,
        } = useApolloTask<ApiHarvesterConfiguration>(task);

        const { uploadSampleFileFromData } = usePolicyFileUpload(props.task.pipeline.id);

        const loginResponse = ref<Record<string, any> | undefined>();
        const sample = ref<Record<string, any> | Record<string, any>[]>(props.task.processedSample || []);
        const finalSample = ref<Record<string, any> | Record<string, any>[]>(props.task.processedSample || []);
        const schedules = ref<ScheduleType[]>([]);

        const loading = computed(() => loadingTask.value || loadingHarvester.value);

        const canBeFinalized = computed(
            () => currentStep.value === 1 && task.value.configuration.response.selectedItems.length,
        );

        const wizardActions = computed(() => [
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: task.value.status !== TaskStatus.Deprecated && canBeFinalized.value && !loading.value,
            },
        ]);

        const scrollUp = () => {
            if (apolloTaskShellRef.value) apolloTaskShellRef.value.scrollUp();
        };

        const finalizeTask = async () => {
            try {
                loadingHarvester.value = true;

                const arraySample = is(Array, finalSample.value) ? finalSample.value : [finalSample.value];
                task.value.configuration.sample = arraySample;

                const shouldClearProcessedSample = await shouldClearHarvesterProcessedSample();
                await save(shouldClearProcessedSample);
                await uploadSampleFileFromData({
                    data: JSON.stringify(arraySample),
                    name: 'sample.json',
                    type: 'application/json',
                    policy: {
                        folder: 'upload',
                        subfolder: `sample/${new Date().valueOf().toString()}`,
                    },
                });
                if (inDraftStatus.value && schedules.value.length) await ScheduleAPI.create(schedules.value);

                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            } finally {
                loadingHarvester.value = false;
            }
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<ApiHarvesterConfiguration>) => {
                task.value = newTask;
            },
        );

        return {
            apolloTaskShellRef,
            currentStep,
            loading,
            wizardActions,
            showFinalizeModal,
            loadingSampleRun,
            loginResponse,
            sample,
            finalSample,
            schedules,
            finalizeTask,
            scrollUp,
        };
    },
});
