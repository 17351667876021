



















































































































































































import { ConfirmModal, FormBlock, TwButton } from '@/app/components';
import { useJsonObject, useSchedule } from '@/app/composable';
import store from '@/app/store';
import { useAPIConnection, useApolloTask } from '@/modules/apollo/composable';
import { RetrievalType } from '@/modules/apollo/constants';
import {
    ApiHarvesterConfiguration,
    ApiHarvesterHeader,
    ApiHarvesterPagination,
    ApiHarvesterParameter,
    ApiHeaderTag,
    ApiHeaderTagConfig,
    ApolloTask,
} from '@/modules/apollo/types';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { equals, isEmpty, isNil } from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { RetrievalSettings } from '../common';
import Schedules from '../common/Schedules.vue';
import APIHarvesterAuthentication from './APIHarvesterAuthentication.vue';
import APIPagination from './APIPagination.vue';
import APIRequest from './APIRequest.vue';
import APIRequestParameters from './APIRequestParameters.vue';
import ExtraHeaders from './ExtraHeaders.vue';

export default defineComponent({
    name: 'SetupAPIHarvester',
    model: { prop: 'harvesterTask', event: 'changed' },
    props: {
        harvesterTask: {
            type: Object as PropType<ApolloTask<ApiHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loginResponse: {
            type: [Object, Array] as PropType<Record<string, any> | Record<string, any>[]>,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        sample: {
            type: [Object, Array],
        },
    },
    components: {
        ValidationObserver,
        FormBlock,
        TwButton,
        APIHarvesterAuthentication,
        ChevronRightIcon,
        APIRequest,
        APIPagination,
        APIRequestParameters,
        ConfirmModal,
        Schedules,
        ExtraHeaders,
        RetrievalSettings,
    },
    setup(props, { emit, root }) {
        const { isValidSchedule } = useSchedule();
        const { getAllPaths } = useJsonObject();

        const showConfirmIgnoreCertificatesModalCallback: Ref<Function | undefined> = ref<Function>();
        const errorAlert = ref<{ title: string; body: string | null; showIgnore: boolean }>();
        const apiValidationRef = ref<any>();
        const loadingSchedules = ref<boolean>(false);
        const savedTask = ref<ApolloTask<ApiHarvesterConfiguration>>(props.harvesterTask);
        const showPartialMatchModal = ref<boolean>(false);
        const showNoMatchModal = ref<boolean>(false);

        const testLoginResponseKeys = computed(() => (props.loginResponse ? Object.keys(props.loginResponse) : []));

        const task = ref<ApolloTask<ApiHarvesterConfiguration>>(props.harvesterTask);

        const retrieveOnce = computed(() => task.value.configuration.retrieval.type === RetrievalType.Once);

        const {
            isRunning,
            isFinalized,
            pipelineFinalized,
            inDeprecatedStatus,
            inDraftStatus,
            hasCompleted,
        } = useApolloTask<ApiHarvesterConfiguration>(
            computed(() => {
                return {
                    ...task.value,
                    configuration: {
                        ...task.value.configuration,
                        fileType: task.value.configuration.fileType ? task.value.configuration.fileType : 'json',
                        retrieval: {
                            ...task.value.configuration.retrieval,
                            type: task.value.configuration.retrieval.type || RetrievalType.Periodic,
                        },
                    },
                } as ApolloTask<ApiHarvesterConfiguration>;
            }),
        );

        const pagination = computed({
            get: (): {
                configuration: ApiHarvesterPagination;
                parameters: ApiHarvesterParameter[];
            } => {
                return {
                    configuration: task.value.configuration.pagination || { type: 'no' },
                    parameters: task.value.configuration.params.parameters.filter(
                        (parameter: ApiHarvesterParameter) => !!parameter.paginateWith,
                    ),
                };
            },
            set: (updateConfiguration: {
                configuration: ApiHarvesterPagination;
                parameters: ApiHarvesterParameter[];
            }) => {
                task.value.configuration.pagination = updateConfiguration.configuration;
                task.value.configuration.params.parameters = [
                    ...task.value.configuration.params.parameters.filter(
                        (parameter: ApiHarvesterParameter) => !parameter.paginateWith,
                    ),
                    ...updateConfiguration.parameters,
                ];
            },
        });

        const isNumberOfSchedulesValid = computed(
            () =>
                task.value.configuration.retrieval.type !== RetrievalType.Immediately &&
                task.value.configuration.retrieval.type !== RetrievalType.Polling &&
                !(props.schedules && props.schedules.length),
        );
        const user = computed(() => store.state.auth.user);
        const hasChanges = computed(() => !equals(savedTask.value, task.value));
        const isOwner = computed(() => task.value?.pipeline.createdById === user.value.id);
        const parameters = computed({
            get: (): ApiHarvesterParameter[] => {
                return task.value.configuration.params.parameters || [];
            },
            set: (newParameters: ApiHarvesterParameter[]) => {
                task.value.configuration.params.parameters = newParameters;
            },
        });
        const { test, validate, responsePrefix, separator } = useAPIConnection(
            task,
            computed({
                get: () => props.loginResponse,
                set: (newLoginResponse: any) => emit('update-login-response', newLoginResponse),
            }),
        );

        const areParametersValid = computed(() =>
            parameters.value.every((param: ApiHarvesterParameter) => !isNil(param.value.value)),
        );

        const areHeadersValid = computed(() =>
            task.value.configuration.params.headers.every(
                (header: ApiHarvesterHeader) => !isNil(header.value) && !isEmpty(header.value),
            ),
        );

        const readOnlyMessage = computed(() => {
            if (!isOwner.value) return 'Only the owner of the pipeline is allowed to update its schedules';
            if (retrieveOnce.value) {
                if (hasCompleted.value) return 'You cannot update a schedule that has already been executed';
                if (isRunning.value) return 'You cannot update a schedule that is currently being executed';
            }
            return null;
        });

        const updateLoginResponse = (event: any) => {
            emit('login-tested', !!event);

            // If the login response is reset, clear all authentication parameters
            if (!event) {
                task.value.configuration.params.parameters.forEach((parameter: ApiHarvesterParameter) => {
                    if (parameter.value.category === 'authentication') {
                        parameter.value.category = undefined;
                        parameter.value.value = undefined;
                    }
                });
                task.value.configuration.params.headerTags.forEach((headerTag: ApiHeaderTag, index: number) => {
                    if (headerTag.tags.some((tag: ApiHeaderTagConfig) => tag.classes === 'loginParam')) {
                        headerTag.tags = [];
                        headerTag.value = '';
                        task.value.configuration.params.headers[index].value = null;
                    }
                });
            }
            emit('update-login-response', event);
        };

        const changeTab = () => emit('next-tab');

        const testAPIConnection = async () => {
            errorAlert.value = undefined;

            if (isFinalized.value) {
                changeTab();
                return;
            }

            if (!apiValidationRef.value) return;
            if (!areParametersValid.value) return;

            const valid = await apiValidationRef.value.validate();
            try {
                validate();
            } catch (e: any) {
                (root as any).$toastr.e(e, 'Error');
            }

            if (!valid) return;

            const invalidSchedules = props.schedules.filter(
                (schedule: ScheduleType) => !isValidSchedule(schedule, retrieveOnce.value),
            );

            if (!pipelineFinalized.value && invalidSchedules.length) {
                (root as any).$toastr.e(
                    'One or more schedules are in the past or have no valid executions between start and end date. Please update them accordingly.',
                    'Invalid Schedules!',
                );
                return;
            }

            if (props.harvesterTask.configuration.auth.method === 'custom' && isNil(props.loginResponse)) {
                (root as any).$toastr.w(
                    'Please test your custom authentication details before you proceed.',
                    'Login not tested',
                );
                return;
            }

            test()
                .then((res: any) => {
                    emit('set-sample', res);
                    changeTab();
                })
                .catch(
                    (e: {
                        message: string;
                        title?: string;
                        body?: string | null;
                        showIgnore?: boolean;
                        partialMatch?: boolean;
                        noMatch?: boolean;
                        sample?: any;
                    }) => {
                        if (e.partialMatch || e.noMatch) emit('set-sample', e.sample);

                        if (e.partialMatch) showPartialMatchModal.value = true;
                        if (e.noMatch) showNoMatchModal.value = true;
                        if (e.message) (root as any).$toastr.e(e.message, 'Error');
                        if (e.title) {
                            errorAlert.value = {
                                title: e.title,
                                body: e.body ?? null,
                                showIgnore: !!e.showIgnore,
                            };
                            emit('scroll-up');
                        }
                    },
                );
        };

        const confirmIgnoreCertificatesModal = (callback: Function) => {
            showConfirmIgnoreCertificatesModalCallback.value = callback;
        };

        const cancelIgnoreCertificates = () => {
            task.value.configuration.params.ignoreCertificates = false;
            showConfirmIgnoreCertificatesModalCallback.value = undefined;
        };

        const confirmIgnoreCertificates = () => {
            if (showConfirmIgnoreCertificatesModalCallback.value instanceof Function)
                showConfirmIgnoreCertificatesModalCallback.value();
            showConfirmIgnoreCertificatesModalCallback.value = undefined;
        };

        const confirmKeepSelection = () => {
            const allPaths = getAllPaths(props.sample || {}, '', responsePrefix, separator);
            const selection: string[] = task.value.configuration.response.selectedItems.filter((item: string) =>
                item.includes(separator),
            );

            task.value.configuration.response.selectedItems = selection.filter((field: string) =>
                allPaths.includes(field),
            );

            showPartialMatchModal.value = false;
            changeTab();
        };

        const confirmResetSelection = () => {
            task.value.configuration.response.selectedItems = [];
            showNoMatchModal.value = false;
            changeTab();
        };

        watch(
            () => task.value,
            (newTask: ApolloTask<ApiHarvesterConfiguration>) => {
                task.value = newTask;
                emit('changed', newTask);
            },
            { deep: true },
        );

        return {
            user,
            task,
            isNumberOfSchedulesValid,
            isFinalized,
            hasChanges,
            isOwner,
            parameters,
            testLoginResponseKeys,
            RetrievalType,
            isRunning,
            apiValidationRef,
            loadingSchedules,
            pipelineFinalized,
            inDeprecatedStatus,
            errorAlert,
            testAPIConnection,
            confirmIgnoreCertificates,
            confirmIgnoreCertificatesModal,
            cancelIgnoreCertificates,
            showConfirmIgnoreCertificatesModalCallback,
            showPartialMatchModal,
            showNoMatchModal,
            confirmKeepSelection,
            confirmResetSelection,
            pagination,
            inDraftStatus,
            updateLoginResponse,
            areParametersValid,
            areHeadersValid,
            retrieveOnce,
            hasCompleted,
            readOnlyMessage,
        };
    },
});
