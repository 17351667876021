



















































































































































































import { Scrollbar, SvgImage } from '@/app/components';
import { useExecutionErrors } from '@/app/composable';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import * as R from 'ramda';
import CleaningFieldView from './CleaningFieldView.vue';
import { Constraint, CleaningFieldConfiguration } from '../../types/cleaning.type';
import { Stats, StatsPerField, TaskStats } from '../../types/task-stats.type';
import { CleaningConfiguration } from '../../types/cleaning.type';
import { TaskExecutionStatus } from '../../constants';

export default defineComponent({
    name: 'CleaningReview',
    components: { Scrollbar, CleaningFieldView, SvgImage },
    props: {
        configuration: {
            type: Object as PropType<CleaningConfiguration>,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        stats: {
            type: Object as PropType<TaskStats<Stats<StatsPerField>>>,
            required: false,
        },
        stepStatus: {
            type: String,
            default: 'draft',
        },
    },
    setup(props) {
        const { errorMessage } = useExecutionErrors();

        const rowsTransformed = computed(() => {
            if (props.stats?.successfulStats?.transformedRecords && props?.stats?.successfulStats?.inputRecords) {
                return (
                    (props.stats?.successfulStats?.transformedRecords / props?.stats.successfulStats?.inputRecords) *
                    100
                ).toFixed(2);
            }
            return 0;
        });

        const rowsDropped = computed(() => {
            if (props?.stats?.successfulStats?.outputRecords && props?.stats?.successfulStats?.inputRecords) {
                return (
                    ((props?.stats?.successfulStats?.inputRecords - props?.stats?.successfulStats?.outputRecords) /
                        props?.stats?.successfulStats?.inputRecords) *
                    100
                ).toFixed(2);
            }
            return 0;
        });

        const fieldsWithConstraints = computed(() => {
            return props.configuration.fields.filter((obj: any) => {
                return obj.constraints.length > 0;
            });
        });

        const fieldsWithNoConstraints = computed(() => {
            const concepts = props.configuration.fields.filter((obj: any) => {
                return obj.constraints.length === 0;
            });
            const splitConcepts: any[] = [];
            let currentArray: any[] = [];
            concepts.forEach((concept: any) => {
                if (currentArray.length === 4) {
                    splitConcepts.push(currentArray);
                    currentArray = [];
                }
                currentArray.push(concept);
            });
            if (currentArray.length > 0) {
                splitConcepts.push(currentArray);
            }

            return splitConcepts;
        });

        const statsInField = (field: CleaningFieldConfiguration) => {
            if (!props.stats) return null;
            if (props.stats?.successfulStats?.statsPerField?.length) {
                return (
                    props.stats?.successfulStats?.statsPerField
                        ?.filter((stats) => R.pluck('id', field.constraints as Constraint[]).includes(stats.id))
                        .reduce((obj, stats) => {
                            return { ...obj, [stats.id]: stats };
                        }, {}) ?? {}
                );
            } else if (
                props.stepStatus === TaskExecutionStatus.Completed &&
                props.stats?.latestExecutionStats?.statsPerField
            ) {
                // step completed successfully, but failed at a later step, so we should consider latest execution stats as successful for this step
                return (
                    props.stats?.latestExecutionStats?.statsPerField
                        ?.filter((stats) => R.pluck('id', field.constraints as Constraint[]).includes(stats.id))
                        .reduce((obj, stats) => {
                            return { ...obj, [stats.id]: stats };
                        }, {}) ?? {}
                );
            }
        };

        const failedStatsInField = (field: CleaningFieldConfiguration) => {
            if (props.stepStatus !== TaskExecutionStatus.Failed) return null;
            return (
                props.stats?.latestExecutionStats?.statsPerField
                    ?.filter((stats) => R.pluck('id', field.constraints as Constraint[]).includes(stats.id))
                    .reduce((obj, stats) => {
                        return { ...obj, [stats.id]: stats };
                    }, {}) ?? {}
            );
        };

        const failedConstraintsInField = (field: any) => {
            if (props.stepStatus !== TaskExecutionStatus.Failed) return null;
            return props.stats?.latestExecutionStats?.statsPerField
                ?.filter(
                    (stats) =>
                        R.pluck('id', field.constraints as Constraint[]).includes(stats.id) &&
                        !R.isNil(stats.errorCode),
                )
                .reduce((obj, stats) => {
                    return { ...obj, [stats.id]: errorMessage(stats.errorCode as number).error.title };
                }, {});
        };

        return {
            statsInField,
            failedStatsInField,
            failedConstraintsInField,
            fieldsWithConstraints,
            fieldsWithNoConstraints,
            TaskExecutionStatus,
            rowsTransformed,
            rowsDropped,
        };
    },
});
