


















































import { TwButton } from '@/app/components';
import { useSchedule } from '@/app/composable';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { HarvesterBlockId, RetrievalType, SQLRetrievalMethod } from '../../../constants';
import { BigQueryHarvesterConfiguration, TFile } from '../../../types';
import { RetrievalSettings, DataRetrievalDetails, Schedules } from '../common';
import BigQueryAuthenticationDetails from './BigQueryAuthenticationDetails.vue';

export default defineComponent({
    name: 'SetupBigQueryHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<BigQueryHarvesterConfiguration>,
            required: true,
        },
        file: {
            type: File as PropType<TFile | null>,
            default: null,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isRunning: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        pipelineFinalized: {
            type: Boolean,
            default: false,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        inDraftStatus: {
            type: Boolean,
            default: false,
        },
        pipelineId: {
            type: String,
            default: '',
        },
        hasAnonymisation: {
            type: Boolean,
            default: false,
        },
        hasCompleted: {
            type: Boolean,
            default: false,
        },
        scheduleDetailsDefined: {
            type: Boolean,
            default: false,
        },
        invalidQuery: {
            type: Boolean,
            default: false,
        },
        containsModificationTerms: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationObserver,
        ChevronRightIcon,
        TwButton,
        BigQueryAuthenticationDetails,
        DataRetrievalDetails,
        RetrievalSettings,
        Schedules,
    },
    setup(props, { emit, root }) {
        const { isValidSchedule } = useSchedule();

        const bigQueryValidationRef = ref<any>(null);

        const bigQueryConfiguration: Ref<BigQueryHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: BigQueryHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const retrieveOnce = computed(() => bigQueryConfiguration.value.retrieval.type === RetrievalType.Once);

        const disableNext = computed(
            () =>
                !props.scheduleDetailsDefined ||
                (bigQueryConfiguration.value.method === SQLRetrievalMethod.Query &&
                    (props.containsModificationTerms || props.invalidQuery)) ||
                !bigQueryConfiguration.value.auth.serviceAccountKey.key,
        );

        const readOnlyMessage = computed(() => {
            if (!props.isOwner) return 'Only the owner of the pipeline is allowed to update its schedules';
            if (retrieveOnce.value) {
                if (props.hasCompleted) return 'You cannot update a schedule that has already been executed';
                if (props.isRunning) return 'You cannot update a schedule that is currently being executed';
            }
            return null;
        });

        const fileUploaded = (data: any) => {
            bigQueryConfiguration.value.auth.serviceAccountKey.key = JSON.stringify(data);
        };

        const validateAndProceed = async () => {
            if (!bigQueryValidationRef.value) return;

            const valid = await bigQueryValidationRef.value.validate();
            if (!valid) return;

            const invalidSchedules = props.schedules.filter(
                (schedule: ScheduleType) => !isValidSchedule(schedule, retrieveOnce.value),
            );

            if (!props.pipelineFinalized && invalidSchedules.length) {
                (root as any).$toastr.e(
                    'One or more schedules are in the past or have no valid executions between start and end date. Please update them accordingly.',
                    'Invalid Schedules!',
                );
                return;
            }

            emit('next-tab');
        };

        const retrievalChanged = (value: RetrievalType) => {
            if (value !== RetrievalType.Periodic && bigQueryConfiguration.value.freshDataColumn)
                bigQueryConfiguration.value.freshDataColumn = null;
        };

        const queryOrTableChanged = () => {
            emit('clear-processed-sample');
            bigQueryConfiguration.value.freshDataColumn = null;
        };

        return {
            bigQueryConfiguration,
            HarvesterBlockId,
            fileUploaded,
            validateAndProceed,
            bigQueryValidationRef,
            retrievalChanged,
            disableNext,
            retrieveOnce,
            queryOrTableChanged,
            readOnlyMessage,
        };
    },
});
