












































import {
    Ref,
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from '@vue/composition-api';
import { ApolloTaskShell } from '../components';
import {
    AnonymisationConfigurationType,
    AnonymisationStep,
    ApolloTask,
    WizardAction,
    AnonymisationTask,
    AnonymisationStepType,
    AnonymisationStatistics,
    TaskStats,
    AnonymisationFieldType,
    AnonymisationFieldStatistics,
} from '../types';
import { useApolloPipeline, useApolloTask, useSampleFields, useSampleRun } from '../composable';
import { PreprocessingBlockId, TaskExecutionStatus, TaskStatus } from '../constants';
import { GeneralAlert, ProcessedSampleView } from '@/app/components';
import AnonymisationConfiguration from '../components/anonymisation/AnonymisationConfiguration.vue';
import AnonymisationReview from '../components/anonymisation/AnonymisationReview.vue';
import { useAxios, useExecutionErrors, useJsonObject, useSockets } from '@/app/composable';
import { ApolloAPI } from '../api';
import { WebSocketsEvents, WebSocketsRoomTypes } from '@/app/constants';
import { MappingFieldConfiguration } from '../types/typings';
import { clone, equals, has, isEmpty, isNil } from 'ramda';
import { MonitoringAPI } from '@/app/api';
import { v4 as uuidv4 } from 'uuid';
import { ExecutionErrorCategory } from '@/app/store/execution-errors';

export default defineComponent({
    name: 'Anonymisation',
    metaInfo() {
        return { title: `Anonymisation${(this as any).task ? ` for: ${(this as any).task.pipeline.name}` : ''}` };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: { ApolloTaskShell, GeneralAlert },
    setup(props, { root }) {
        const anonymisation: AnonymisationTask = {
            blockId: PreprocessingBlockId.Anonymisation,
            steps: [
                {
                    key: AnonymisationStep.Configuration,
                    name: 'Configuration',
                    component: AnonymisationConfiguration,
                    scrollable: false,
                },
                {
                    key: AnonymisationStep.SamplePreview,
                    name: 'Review Rules',
                    component: ProcessedSampleView,
                    scrollable: false,
                },
                { key: AnonymisationStep.Confirm, name: 'Confirm', component: AnonymisationReview, scrollable: false },
            ],
        };
        const restartedStep = ref<boolean>(false);
        const isValid = ref<boolean>(true);
        const showFinalizeModal = ref<boolean>(false);
        const savedTask = ref<ApolloTask<AnonymisationConfigurationType> | undefined>();
        const task = ref<ApolloTask<AnonymisationConfigurationType> | undefined>();
        const currentStep = ref<AnonymisationStep>(AnonymisationStep.Configuration);
        const fieldMappingConfigurations = ref<Record<string, MappingFieldConfiguration>>();
        const statistics = ref<TaskStats<AnonymisationStatistics> | undefined>();
        const { getFixedJSON } = useJsonObject();
        const { extractMappingFieldNames } = useSampleFields();
        const { exec, loading: loadingAnonymisation } = useAxios(true);
        const {
            harvester,
            mapping,
            loading: loadingPipeline,
            isUnderRevise,
            isLargeFilesHarvester,
            fetchPipeline,
        } = useApolloPipeline(props.id, undefined, false);
        const {
            isFinalized,
            canRevise,
            save: saveTask,
            finalize: finalizeTask,
            inDraftStatus,
            inUpdateStatus,
            initialiseNewField,
            shouldUpdateAssetsAfterRevise,
            updateAssetsAfterRevise,
        } = useApolloTask(task);
        const { errorMessage } = useExecutionErrors();

        const updateProcessedSample = (sampleData: any | null) => {
            task.value!.processedSample = sampleData || undefined;
            if (!isNil(sampleData)) next(false);
        };

        const { loadingSampleRun, executeSampleRun, onMessage } = useSampleRun(task, root, updateProcessedSample);

        const loading = computed(() => loadingAnonymisation.value || loadingPipeline.value || loadingSampleRun.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'sample-run',
                show:
                    !isFinalized.value &&
                    currentStep.value === AnonymisationStep.Configuration &&
                    (!processedSample.value || hasFieldConfigurationChanges.value),
                enabled: isValid.value,
            },
            {
                key: 'view-processed-sample',
                show:
                    !isFinalized.value &&
                    currentStep.value === AnonymisationStep.Configuration &&
                    !!processedSample.value &&
                    !loadingSampleRun.value &&
                    !hasFieldConfigurationChanges.value,
                enabled: true,
            },
            {
                key: 'save',
                show: !isFinalized.value,
                enabled: hasChanges.value && isValid.value,
                showCancel: !isFinalized.value && hasChanges.value,
            },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled:
                    !isFinalized.value &&
                    !isUnderReviseAndNoChange.value &&
                    isValid.value &&
                    currentStep.value === AnonymisationStep.Confirm,
            },
            { key: 'revise', label: 'Revise', show: canRevise.value, enabled: canRevise.value },
        ]);

        const currentStepInfo = computed(() =>
            anonymisation.steps.find((step: AnonymisationStepType) => step.key === currentStep.value),
        );

        const sample = computed(() => {
            if (!task.value?.inputSample) return [];
            return getFixedJSON(task.value.inputSample);
        });

        const hasChanges = computed(() => {
            return !equals(savedTask.value?.configuration, task.value?.configuration);
        });

        const hasFieldConfigurationChanges = computed(() => {
            return !equals(savedTask.value?.configuration.fields, task.value?.configuration.fields);
        });

        const errors: Ref<
            { category: string; error: { title: string; description: string }; linkToHistory: boolean }[]
        > = computed(() => {
            if (isNil(statistics.value) || isNil(statistics.value.latestExecutionStats)) return [];
            if (statistics.value?.hasFailed) {
                return [
                    {
                        category: ExecutionErrorCategory.Anonymiser,
                        error: {
                            title: 'Anonymisation has failed to complete execution',
                            description: 'Please check the execution logs for more details.',
                        },
                        linkToHistory: true,
                    },
                ];
            }

            return statistics.value.latestExecutionStats.statsPerField.reduce(
                (
                    acc: { category: string; error: { title: string; description: string }; linkToHistory: boolean }[],
                    field: AnonymisationFieldStatistics,
                ) => {
                    if (!isNil(field.errorCode)) acc.push({ ...errorMessage(field.errorCode), linkToHistory: false });
                    return acc;
                },
                [],
            );
        });

        const isUnderReviseAndNoChange = computed(
            () =>
                isUnderRevise.value &&
                !task.value?.configuration.hasChangesAfterRevise &&
                errors.value.length > 0 &&
                !hasChanges.value,
        );

        const fetch = async () => {
            await fetchPipeline();
            task.value = (await exec(ApolloAPI.getTask(props.id, 'anonymisation')))?.data;
            if (!task.value) return;
            const mappedFields = extractMappingFieldNames(mapping.value!.configuration.fields);

            // initialise fields on first load
            if (task.value.configuration.fields.length === 0) {
                task.value.configuration.fields = mappedFields.map(initialiseNewField); // this is the first time so we mark it as "saved"
                savedTask.value = clone(task.value);
            } else {
                // this is a later visit so any changes to the task we want to mark as unsaved
                // if there are any changes like removed fields or addition of the anonymisationIdentifier
                savedTask.value = clone(task.value);
                const mappedFieldNames = mappedFields.reduce(
                    (acc: Record<string, MappingFieldConfiguration>, f: MappingFieldConfiguration) => {
                        acc[f.name] = f;
                        return acc;
                    },
                    {},
                );
                const fieldsByName = task.value.configuration.fields.reduce(
                    (acc: Record<string, AnonymisationFieldType>, f: AnonymisationFieldType) => {
                        acc[f.name] = clone(f);
                        return acc;
                    },
                    {},
                );
                task.value.configuration.fields = Object.keys(mappedFieldNames).map((name: string) => {
                    if (has(name, fieldsByName)) {
                        const field: AnonymisationFieldType = fieldsByName[name];
                        if (!has('anonymisationIdentifier', field))
                            return {
                                ...(field as AnonymisationFieldType),
                                anonymisationIdentifier: uuidv4(),
                            };
                        return field;
                    }
                    return initialiseNewField(mappedFieldNames[name]);
                });
            }

            // create an object where the key is the anonymisationIdentifier
            // and the value is the mapping configuration
            fieldMappingConfigurations.value = mappedFields.reduce(
                (acc: Record<string, MappingFieldConfiguration>, field: any) => {
                    const taskField = task.value?.configuration.fields.find(
                        (f: AnonymisationFieldType) => f.name === field.name,
                    );
                    if (taskField) acc[taskField.anonymisationIdentifier] = field;
                    return acc;
                },
                {},
            );
            // get stats
            // draft and streaming pipelines or pipelines executed with spark have no stats currently
            if (
                !inDraftStatus.value &&
                !isLargeFilesHarvester.value &&
                (!inUpdateStatus.value || isUnderRevise.value)
            ) {
                const res: TaskStats<AnonymisationStatistics> = (
                    await exec(MonitoringAPI.taskStats(props.id, task.value!.id))
                )?.data;

                statistics.value = res;
            }
        };

        const save = async (notify: boolean = true, clearProcessedSample = true) => {
            if (task.value && isUnderRevise.value) task.value.configuration.hasChangesAfterRevise = hasChanges.value;
            if (clearProcessedSample && task.value) task.value.processedSample = [];
            if (notify) (root as any).$toastr.s('Anonymisation configuration saved successfully', 'Success');
            savedTask.value = await saveTask(clearProcessedSample || hasFieldConfigurationChanges.value);
            task.value = clone(savedTask.value);
        };

        const finalize = async () => {
            const reviseMethod = shouldUpdateAssetsAfterRevise();
            restartedStep.value = reviseMethod;

            showFinalizeModal.value = true;
            if (!task.value) return;

            if (reviseMethod) {
                await updateAssetsAfterRevise();
            }
            await finalizeTask();
        };

        const unlockJob = async () => {
            await exec(ApolloAPI.unlock(props.id));
        };

        const runOnSample = async () => {
            executeSampleRun();
        };
        const canProceedWithEmptySample = computed(() => !isEmpty(task.value?.processedSample));
        const processedSample = computed(() => {
            if (hasFieldConfigurationChanges.value || !canProceedWithEmptySample.value) return null;
            return task.value?.processedSample;
        });

        const next = async (performSampleRun = true) => {
            if (hasChanges.value) await save(false);
            // if no processed sample in configuration page then sample run needs to be run
            if (performSampleRun && currentStep.value === AnonymisationStep.Configuration && !processedSample.value) {
                runOnSample();
                return;
            }

            isValid.value = true; // set to true since after step 1 it should be true
            // proceed to next step
            currentStep.value += 1;
        };

        const previous = () => {
            currentStep.value -= 1;
        };

        const changeStep = async (step: AnonymisationStep) => {
            if (step < currentStep.value) previous();
            else if (step > currentStep.value) next();
        };

        const reviseTask = async () => {
            if (!task.value) return;
            try {
                exec(ApolloAPI.reviseTask(props.id, 'anonymisation'))
                    .then(fetchPipeline)
                    .then(() => {
                        if (task.value) {
                            task.value.status = TaskStatus.Updating;
                            task.value.executionStatus = TaskExecutionStatus.Updating;
                            task.value.processedSample = [];
                            task.value.configuration.hasChangesAfterRevise = false;
                            savedTask.value = clone(task.value);
                        }
                    });

                currentStep.value = AnonymisationStep.Configuration;
                (root as any).$toastr.s(
                    'The configuration of the anonymisation step is now available for updates.',
                    'Success',
                );
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the anonymisation step failed', 'Failed');
            }
        };

        onMounted(async () => {
            window.addEventListener('beforeunload', unlockJob);
        });

        const { subscribe, unsubscribe, leaveSocketRoom } = useSockets();

        onMounted(async () => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onUnmounted(async () => {
            unlockJob();
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.id);
        });

        watch(
            () => isFinalized.value,
            (newIsFinalized: boolean) => {
                if (newIsFinalized) currentStep.value = AnonymisationStep.Confirm;
            },
            { immediate: true },
        );

        // initial load
        fetch();

        return {
            anonymisation,
            loading,
            task,
            sample,
            harvester,
            isFinalized,
            canRevise,
            wizardActions,
            currentStep,
            currentStepInfo,
            showFinalizeModal,
            fieldMappingConfigurations,
            statistics,
            loadingSampleRun,
            processedSample,
            isUnderRevise,
            hasChanges,
            isValid,
            restartedStep,
            AnonymisationStep,
            changeStep,
            save,
            finalize,
            next,
            previous,
            reviseTask,
            runOnSample,
        };
    },
});
