




















































import { AlertBanner } from '@/app/components';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { ApolloTaskShell } from '../../../components';
import { useDatabaseHarvester } from '../../../composable';
import { ApolloTask, HarvesterStep, SQLHarvesterConfiguration, TaskStep, WizardAction } from '../../../types';

export default defineComponent({
    name: 'SQLHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<SQLHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { ApolloTaskShell, ExclamationIcon, AlertBanner },
    setup(props, { root, emit }) {
        const taskRef = ref(props.task);

        const {
            isFinalized,
            currentStep,
            scheduleDetailsDefined,
            invalidParams,
            errorAlert,
            loadingTask,
            isOwner,
            inDraftStatus,
            isRunning,
            pipelineFinalized,
            schedules,
            loadingSampleRun,
            hasAnonymisation,
            showFinalizeModal,
            hasCompleted,
            sampleWithoutAdditional,
            sampleFields,
            taskStructure,
            queryIncludesModification,
            queryIncludesForbiddenTerms,
            parsedQuery,
            sqlQueryPreview,
            clearProcessedSample,
            runOnSample,
            nextTab,
            finalizeTask,
        } = useDatabaseHarvester(taskRef, root, emit);

        const finalLoading = computed(() => props.loading || loadingTask.value);

        const connectionDetailsDefined = computed(
            () =>
                !!taskRef.value.configuration.connection.host &&
                !!taskRef.value.configuration.connection.port &&
                !!taskRef.value.configuration.connection.username &&
                !!taskRef.value.configuration.connection.password &&
                !!taskRef.value.configuration.connection.database,
        );

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'sample-run',
                show:
                    !isFinalized.value &&
                    currentStep.value === HarvesterStep.Setup &&
                    !taskRef.value.processedSample?.length,
                enabled:
                    !!taskRef.value.configuration.sqlType &&
                    connectionDetailsDefined.value &&
                    (!!taskRef.value.configuration.query || !!taskRef.value?.configuration.table) &&
                    scheduleDetailsDefined.value,
            },
            {
                key: 'view-processed-sample',
                show:
                    !isFinalized.value &&
                    currentStep.value === HarvesterStep.Setup &&
                    !!taskRef.value.processedSample?.length,
                enabled: scheduleDetailsDefined.value,
            },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled:
                    currentStep.value === HarvesterStep.Review &&
                    scheduleDetailsDefined.value &&
                    !invalidParams.value &&
                    taskRef.value.configuration.identifierColumns.length > 0,
            },
        ]);

        const sqlTypeChanged = () => {
            taskRef.value.configuration.connection = {
                host: null,
                port: null,
                username: null,
                password: null,
                database: null,
            };
            clearProcessedSample();
        };

        return {
            currentStep,
            errorAlert,
            wizardActions,
            loadingTask,
            taskRef,
            finalLoading,
            isOwner,
            inDraftStatus,
            isRunning,
            pipelineFinalized,
            isFinalized,
            schedules,
            scheduleDetailsDefined,
            sqlTypeChanged,
            runOnSample,
            nextTab,
            loadingSampleRun,
            hasAnonymisation,
            clearProcessedSample,
            finalizeTask,
            showFinalizeModal,
            hasCompleted,
            sampleWithoutAdditional,
            sampleFields,
            taskStructure,
            queryIncludesModification,
            queryIncludesForbiddenTerms,
            parsedQuery,
            sqlQueryPreview,
        };
    },
});
