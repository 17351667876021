












































































































































































































































































import { InputErrorIcon, MultivalueEditor } from '@/app/components';
import { dynamicValues } from '@/app/constants';
import { S } from '@/app/utilities';
import { integerValidator, maxLengthValidator } from '@/app/validators';
import { AdditionalResponseDataType } from '@/modules/apollo/types';
import { formatDate, getDynamicDate } from '@/modules/apollo/utils';
import { PlusCircleIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { dissocPath, equals, is } from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);
extend('max_param_name', {
    ...maxLengthValidator,
    message: (key: string, rule: any) => {
        return `You cannot add a parameter name longer than ${rule.length} characters`;
    },
});
extend('max_param_value', {
    ...maxLengthValidator,
    message: (key: string, rule: any) => {
        return `You cannot add a parameter value longer than ${rule.length} characters`;
    },
});

extend('integer', { ...integerValidator, message: 'Parameter value needs to be a number' });

export default defineComponent({
    name: 'AdditionalResponseData',
    components: {
        MultivalueEditor,
        ValidationObserver,
        ValidationProvider,
        InputErrorIcon,
        PlusCircleIcon,
        ExclamationIcon,
    },
    model: {
        prop: 'additionalData',
        event: 'update',
    },
    props: {
        additionalData: {
            type: Array as PropType<AdditionalResponseDataType[]>,
            default: () => [],
        },
        requestParams: {
            type: Array,
            default: () => [],
        },
        json: {
            type: [Object, Array, String],
            required: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, { emit, root }) {
        const additionalResponseData: Ref<AdditionalResponseDataType[]> = computed({
            get: () => props.additionalData,
            set: (newAdditionalData: AdditionalResponseDataType[]) => {
                emit('update', newAdditionalData);
                emit('change', newAdditionalData);
            },
        });

        const paramValidationRef = ref<any>(false);

        const parameterTypes = computed(() => {
            const types = [
                { value: 'static', label: 'Static' },
                { value: 'dynamic', label: 'Dynamic' },
            ];

            if (props.requestParams.length > 0) {
                types.push({ value: 'request', label: 'From request parameters' });
            }

            return types;
        });

        const allDynamicValues = computed(() => {
            return dynamicValues.reduce((acc: any, val: string) => {
                acc.push({ label: val, value: val });
                return acc;
            }, []);
        });

        const allRequestParams = computed(() => {
            return (props.requestParams as Array<any>).reduce((acc: any, val: any) => {
                acc.push({ label: `${val.key.name} (${val.key.type})`, value: val });
                return acc;
            }, []);
        });

        const getValuePrefixLabel = (dynamicType: string) => {
            if (!dynamicType || !is(String, dynamicType)) return '';
            if (dynamicType.endsWith('_ago') || dynamicType.endsWith('_later')) return 'timestamp of';
            return '';
        };

        const getNeedsValue = (dynamicType: string) => {
            if (!dynamicType || !is(String, dynamicType)) return false;
            if (dynamicType.endsWith('_ago') || dynamicType.endsWith('_later')) return true;
            return false;
        };

        const getValueLabel = (dynamicType: any) => {
            if (!is(String, dynamicType) && S.has('key', dynamicType))
                return `${dynamicType.key.name} (${dynamicType.key.type})`;

            return dynamicType.split('_').join(' ');
        };

        const getRequiredFields = (type: string, dynamicType: string | undefined): string[] => {
            switch (type) {
                case 'static':
                case 'request':
                    return ['key', 'type', 'value'];
                case 'dynamic':
                    if (dynamicType && getNeedsValue(dynamicType)) return ['key', 'type', 'dynamicType', 'value'];
                    return ['key', 'type', 'dynamicType'];
                default:
                    return ['key', 'type'];
            }
        };

        const getMultivalueEditorRequiredFields = (data: any) => {
            if (!data.key || !data.type) return ['key', 'type'];
            return getRequiredFields(data.type, data.dynamicType);
        };

        const siblingKeys = computed(() => {
            if (is(Array, props.json) && (props.json as any[]).length > 0) return Object.keys((props.json as any[])[0]);
            if (is(Object, props.json)) return Object.keys(props.json as any[]);
            return [];
        });

        const invalidParams = computed(() =>
            additionalResponseData.value.some((data: { key: string }) => siblingKeys.value.includes(data.key)),
        );

        const add = (item: Record<string, any>) => {
            if (siblingKeys.value.includes(item.key))
                (root as any).$toastr.e(
                    `Data key '${S.sanitizeHtml(item.key)}' already exists in response root`,
                    'Error',
                );
            else {
                const requiredFields = getRequiredFields(item.type, item.dynamicType);
                const cleanItem: any = {};
                const keys = Object.keys(item);
                for (let i = 0; i < keys.length; i++) {
                    if (requiredFields.includes(keys[i])) cleanItem[keys[i]] = item[keys[i]];
                }
                if (cleanItem.type === 'static') cleanItem.displayValue = cleanItem.value;
                else if (cleanItem.type === 'dynamic') {
                    const now = new Date();
                    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
                    cleanItem.format = cleanItem.dynamicType === 'current_date' ? 'YYYY-MM-DD' : 'ISO 8601';
                    cleanItem.displayValue = formatDate(
                        cleanItem.format,
                        new Date(getDynamicDate(cleanItem.dynamicType, utc, cleanItem.value)),
                    );
                } else if (cleanItem.type === 'request') {
                    if (cleanItem.value.value.category === 'static')
                        cleanItem.displayValue = cleanItem.value.value.value;
                    else if (cleanItem.value.value.category === 'dynamic') {
                        const now = new Date();
                        const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
                        cleanItem.displayValue = formatDate(
                            cleanItem.value.value.format,
                            new Date(
                                getDynamicDate(
                                    cleanItem.value.value.value.option,
                                    utc,
                                    cleanItem.value.value.value.value,
                                ),
                            ),
                        );
                    } else if (cleanItem.value.value.category === 'authentication')
                        cleanItem.displayValue = cleanItem.value.value.sampleValue || cleanItem.value.value.value;
                } else {
                    cleanItem.displayValue = cleanItem.value.value.value;
                    if (is(String, cleanItem.dynamicType)) cleanItem.format = 'ISO 8601';
                }
                additionalResponseData.value.push(cleanItem);
            }
        };

        const remove = (idx: number) => {
            additionalResponseData.value.splice(idx, 1);
        };

        const edit = (idx: number) => {
            const item = additionalResponseData.value[idx];
            if (siblingKeys.value.includes(item.key)) {
                additionalResponseData.value.splice(idx, 1);
                (root as any).$toastr.e(
                    `Data key '${S.sanitizeHtml(item.key)}' already exists in response root`,
                    'Error',
                );
            }
        };

        // If requests params have changes we remove from the additional parameters any
        // additional parameter using the request param removed
        watch(
            () => props.requestParams,
            (updatedRequestParams: any[]) => {
                let hasChange = false;

                const cleanData = additionalResponseData.value.reduce((acc: any[], item: any) => {
                    if (item.type === 'request') {
                        if (
                            updatedRequestParams.some((requestParam: any) =>
                                equals(
                                    dissocPath(['value', 'sampleValue'], requestParam),
                                    dissocPath(['value', 'sampleValue'], item.value),
                                ),
                            )
                        )
                            acc.push(item);
                        else hasChange = true;
                    } else acc.push(item);
                    return acc;
                }, []);

                if (hasChange) {
                    emit('update', cleanData);
                    emit('change', cleanData);
                }
            },
            { immediate: true },
        );

        return {
            additionalResponseData,
            paramValidationRef,
            allDynamicValues,
            allRequestParams,
            parameterTypes,
            getNeedsValue,
            getMultivalueEditorRequiredFields,
            getValuePrefixLabel,
            getValueLabel,
            add,
            remove,
            edit,
            siblingKeys,
            invalidParams,
        };
    },
});
