
































































































































































































































import { ExclamationIcon, LinkIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { FieldPath } from '../../components';
import { AlrternateNames } from '../../types';
import { CleaningConfiguration, CleaningFieldConfiguration, ConstraintStats } from '../../types/cleaning.type';
import ViewConstraint from './constraints/ViewConstraint.vue';

export default defineComponent({
    name: 'CleaningFieldView',
    components: { ViewConstraint, FieldPath, ExclamationIcon, LinkIcon },
    props: {
        configuration: {
            type: Object as PropType<CleaningConfiguration>,
            required: true,
        },
        field: {
            type: Object as PropType<CleaningFieldConfiguration>,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        cleaningDetails: {
            type: Object,
            default: null,
        },
        failedConstraints: {
            type: Object,
            default: null,
        },
        failedStats: {
            type: Object,
            default: null,
        },
        modified: {
            type: Boolean,
            default: false,
        },
        problematicSampleConstraints: {
            type: Array as PropType<ConstraintStats[]>,
            default: () => [],
        },
        alternateName: {
            type: Object as PropType<AlrternateNames[string]>,
            default: null,
        },
        showAlternateNaming: {
            type: Boolean,
            default: false,
        },
        usedInAdvancedRules: {
            type: Number,
            default: 0,
        },
    },
    setup(props, { emit }) {
        const expanded = ref<boolean>(false);

        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const fieldClicked = (event: MouseEvent) => {
            if (props.disabled) {
                expanded.value = !expanded.value;
            } else {
                emit('toggle-selection', (event.metaKey && isMacOS) || (event.ctrlKey && !isMacOS));
            }
        };

        const constraints = computed(() => props.field.constraints);

        const constraintDetails = (constraint: any) => {
            if (props.cleaningDetails && props.cleaningDetails[constraint.id.toString()] !== null) {
                return props.cleaningDetails[constraint.id.toString()];
            }
            if (props.failedStats) return 0;
            return null;
        };

        const failedConstraintDetails = (constraint: any) => {
            if (props.failedStats && constraint.id.toString() in props.failedStats) {
                return props.failedStats[constraint.id.toString()];
            }
            if (props.failedStats) {
                return 0;
            }
            return null;
        };

        const isConstraintFail = (constrant: any) => {
            if (props.failedConstraints) {
                return constrant.id in props.failedConstraints;
            }
            if (props.cleaningDetails) {
                return false;
            }
            return null;
        };

        const classes = computed(() => {
            if (props.selected) return 'rounded-r border-primary-700';
            return 'rounded border-white';
        });

        const rowsDropped = computed(() => {
            if (props.cleaningDetails) {
                const dropped = constraints.value.filter((constraint: any) => constraint.outliersRule.type === 'DROP');
                if (dropped.length > 0) {
                    return dropped
                        .map((constraint: any) => Number(props.cleaningDetails[constraint.id.toString()]?.dropped ?? 0))
                        .reduce((a: number, b: number) => a + b);
                }
            }
            return 0;
        });

        const failedRowsDropped = computed(() => {
            if (props.failedStats) {
                const dropped = constraints.value.filter((constraint: any) => constraint.outliersRule.type === 'DROP');
                if (dropped.length > 0) {
                    return dropped
                        .map((constraint: any) =>
                            constraint.id.toString() in props.failedStats
                                ? Number(props.failedStats[constraint.id.toString()]?.dropped ?? 0)
                                : 0,
                        )
                        .reduce((a: number, b: number) => a + b);
                }
            }
            return 0;
        });

        const rowsReplaced = computed(() => {
            if (props.cleaningDetails) {
                const replaced = constraints.value.filter((constraint: any) => constraint.outliersRule.type !== 'DROP');
                if (replaced.length > 0) {
                    return replaced
                        .map((constraint: any) =>
                            Number(props.cleaningDetails[constraint.id.toString()]?.transformed ?? 0),
                        )
                        .reduce((a: number, b: number) => a + b);
                }
            }
            return 0;
        });

        const failedRowsReplaced = computed(() => {
            if (props.failedStats) {
                const replaced = constraints.value.filter((constraint: any) => constraint.outliersRule.type !== 'DROP');
                if (replaced.length > 0) {
                    return replaced
                        .map((constraint: any) => Number(props.failedStats[constraint.id.toString()]?.transformed ?? 0))
                        .reduce((a: number, b: number) => a + b);
                }
            }
            return 0;
        });

        const failedRules = computed(() => {
            if (props.failedConstraints) {
                return Object.keys(props.failedConstraints).length;
            }
            return 0;
        });

        const successfulRules = computed(() => {
            if (props.cleaningDetails && props.failedConstraints) {
                const rulesExecutedAll = Object.keys(props.cleaningDetails);
                const rulesFailed = Object.keys(props.failedConstraints);
                const rulesExecutedLast = Object.keys(props.failedStats).filter(
                    (key: any) => !rulesFailed.includes(key),
                );
                const rulesExecuted = [...new Set([...rulesExecutedAll, ...rulesExecutedLast])];
                return rulesExecuted.length;
            }
            if (!props.cleaningDetails && props.failedConstraints) {
                const rulesFailed = Object.keys(props.failedConstraints);
                const rulesExecutedLast = Object.keys(props.failedStats).filter(
                    (key: any) => !rulesFailed.includes(key),
                );
                return rulesExecutedLast.length;
            }
            if (props.cleaningDetails && !props.failedConstraints) {
                return Object.keys(props.cleaningDetails).length;
            }
            return 0;
        });

        const sampleFailedConstraints = computed(() =>
            props.problematicSampleConstraints.filter((constraint) => !!constraint.error_code),
        );

        const sampleDropConstraints = computed(() =>
            props.problematicSampleConstraints.filter((constraint) => !constraint.error_code && constraint.dropped > 0),
        );

        return {
            classes,
            expanded,
            rowsDropped,
            failedRowsDropped,
            rowsReplaced,
            failedRowsReplaced,
            constraintDetails,
            failedConstraintDetails,
            isConstraintFail,
            successfulRules,
            failedRules,
            constraints,
            fieldClicked,
            sampleFailedConstraints,
            sampleDropConstraints,
        };
    },
});
