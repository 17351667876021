















































import { FormBlock } from '@/app/components';
import { HarvesterBlockId } from '@/modules/apollo/constants';
import { HarvesterConfiguration } from '@/modules/apollo/types';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleFrequency } from '@/modules/workflow-designer/constants';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import Schedule from './Schedule.vue';

type RetrievalConfiguration = HarvesterConfiguration & { retrieval: { type: string } };

export default defineComponent({
    name: 'Schedules',
    components: {
        FormBlock,
        Schedule,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        pipelineId: {
            type: String,
            required: true,
        },
        configuration: {
            type: Object as PropType<RetrievalConfiguration>,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        frequencies: {
            type: Array as PropType<ScheduleFrequency[]>,
            default: () => ['hourly', 'daily', 'weekly', 'monthly'],
        },
        startDate: {
            type: [Date, Number],
            required: false,
        },
        defaultStartDate: {
            type: Boolean,
            default: false,
        },
        schedulesPerFrequency: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        requiredSchedulesMessage: {
            type: String,
            default: `data check-in pipeline to be executed`,
        },
        frequency: {
            type: String,
            default: null,
        },
        blockId: {
            type: String as PropType<HarvesterBlockId>,
            required: true,
        },
        batchable: {
            type: Boolean,
            default: false,
        },
        readOnlyMessage: {
            type: String,
            default: null,
        },
    },
    setup(props, { root, emit }) {
        const editingSchedule = ref<boolean>(false);
        const closeScheduleEditor = ref<boolean>(false);
        const schedulesError = ref<string | null>(null);
        const loadingSchedules = ref<boolean>(false);
        const schedulerError = ref<boolean>(false);
        const retrievalType = computed(() => props.configuration.retrieval?.type);

        const oldFrequency = computed(() => {
            if (
                props.schedules.find(
                    (schedule: { frequency: string }) => schedule.frequency === ScheduleFrequency.Hourly,
                )
            )
                return ScheduleFrequency.Hourly;

            if (
                props.schedules.find(
                    (schedule: { frequency: string }) => schedule.frequency === ScheduleFrequency.Daily,
                )
            )
                return ScheduleFrequency.Daily;

            return props.frequency;
        });

        const loadingSchedule = ref<boolean>(false);

        const getSchedules = () => {
            // in batchable mode prevent fetching schedules since it will override existing created schedules
            // and only in case schedules batch is created emit the batch to persist schedules
            if (props.batchable && props.schedules.length) {
                emit('updated-schedules', props.schedules);
                return;
            }

            loadingSchedules.value = true;
            emit('loading-schedules', loadingSchedules.value);
            ScheduleAPI.getSchedules(props.pipelineId)
                .then((res: any) => {
                    const fetchedSchedules = res.data;
                    if (retrievalType.value !== 'once')
                        fetchedSchedules.forEach((schedule: ScheduleType) => {
                            schedule.endDate = new Date(dayjs(new Date(schedule.endDate as Date)).format());
                        });
                    emit('updated-schedules', fetchedSchedules);
                    if (fetchedSchedules.length) closeScheduleEditor.value = true;
                    emit('loading-schedules', loadingSchedules.value);
                })
                .catch((e) => {
                    emit('loading-schedules', loadingSchedules.value);
                    schedulerError.value = e.response?.status === 500;
                })
                .finally(() => {
                    loadingSchedules.value = false;
                });
        };

        const setLoading = (loadingValue: boolean) => {
            loadingSchedule.value = loadingValue;
            emit('set-loading', loadingValue);
        };

        const addSchedule = (schedule: ScheduleType) => {
            const updatedSchedules = R.clone(props.schedules);
            updatedSchedules.push(schedule);
            if (!props.batchable) {
                ScheduleAPI.create([schedule])
                    .then(async () => {
                        (root as any).$toastr.s('Schedule has been created successfully', 'Success');
                        emit('updated-schedules', updatedSchedules);
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                emit('updated-schedules', updatedSchedules);
            }
        };

        const updateSchedule = async (event: { idx: number; schedule: ScheduleType }) => {
            const { idx, schedule } = event;
            const updatedSchedules = R.clone(props.schedules);
            updatedSchedules[idx] = schedule;
            schedulesError.value = null;
            if (schedule.id && !props.batchable) {
                setLoading(true);
                ScheduleAPI.update(schedule.id, schedule)
                    .then(() => {
                        emit('updated-schedules', updatedSchedules);
                        (root as any).$toastr.s('Schedule has been updated successfully', 'Success');
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                emit('updated-schedules', updatedSchedules);
            }
        };

        const changeScheduleStatus = async (schedule: any) => {
            const updatedSchedules = R.clone(props.schedules);
            const foundSchedule = updatedSchedules.find((s: ScheduleType) => s.id === schedule.id);
            if (!foundSchedule) return;
            if (schedule.isEnabled) {
                await ScheduleAPI.deactivate(schedule.id);
                foundSchedule.isEnabled = false;
            } else {
                await ScheduleAPI.activate(schedule.id);
                foundSchedule.isEnabled = true;
            }
            emit('updated-schedules', updatedSchedules);
        };

        const deleteSchedule = (id: string) => {
            const updatedSchedules = props.schedules.filter((schedule: ScheduleType) => schedule.id !== id);
            if (!props.batchable)
                ScheduleAPI.delete(id).then(() => {
                    emit('updated-schedules', updatedSchedules);
                });
            else emit('updated-schedules', updatedSchedules);
        };

        getSchedules();

        const scheduleRequired = computed(() => retrievalType.value !== 'immediately');

        const deleteSchedules = async () => {
            if (!props.batchable) {
                const promises: any = [];
                if (props.schedules.length) {
                    props.schedules.forEach((schedule: any) => {
                        promises.push(ScheduleAPI.delete(schedule.id));
                    });
                    await Promise.all(promises);
                    closeScheduleEditor.value = true;
                    emit('updated-schedules', []);
                }
            } else {
                emit('updated-schedules', []);
            }
        };

        watch(
            () => props.configuration?.retrieval?.type,
            (retrieval: string) => {
                if (retrieval) deleteSchedules();
            },
        );

        watch(
            () => props.frequency,
            async (frequency: string) => {
                if (frequency && oldFrequency.value && oldFrequency.value !== frequency) deleteSchedules();
            },
        );

        return {
            editingSchedule,
            addSchedule,
            updateSchedule,
            deleteSchedule,
            changeScheduleStatus,
            loadingSchedules,
            schedulesError,
            closeScheduleEditor,
            scheduleRequired,
            retrievalType,
            loadingSchedule,
            schedulerError,
        };
    },
});
