



































import { ApolloTask, LargeFilesHarvesterConfiguration, TaskStep, TFile, WizardAction } from '@/modules/apollo/types';
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from '@vue/composition-api';
import { useApolloTask, usePolicyFileUpload, useSampleRun } from '@/modules/apollo/composable';
import { ApolloTaskShell } from '../../../components';
import { ApolloAPI } from '@/modules/apollo/api';
import { useAxios } from '@vue-composable/axios';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { useSockets } from '@/app/composable';
import { is } from 'ramda';
import { FileType, RetrievalType, TaskStatus } from '@/modules/apollo/constants';
import { ScheduleAPI } from '@/modules/workflow-designer/api';

export default defineComponent({
    name: 'LargeFilesHarvester',
    components: { ApolloTaskShell },
    props: {
        task: {
            type: Object as PropType<ApolloTask<LargeFilesHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root }: { root: any }) {
        const { exec } = useAxios(true);
        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();
        const { uploadPolicyFiles, fileProgress } = usePolicyFileUpload(props.task.pipeline.id);

        const taskRef: Ref<ApolloTask<LargeFilesHarvesterConfiguration>> = ref<
            ApolloTask<LargeFilesHarvesterConfiguration>
        >(props.task);

        const {
            loading: loadingTask,
            isFinalized,
            inDraftStatus,
            save,
            finalize,
            shouldClearHarvesterProcessedSample,
        } = useApolloTask(taskRef);

        const currentStep = ref<number>(0);
        const schedules = ref<ScheduleType[]>([]);
        const sample = ref(props.task.processedSample);
        const sampleFile = ref<TFile | null>(null);
        const showFinalizeModal = ref<boolean>(false);

        const { executeSampleRun, loadingSampleRun, onMessage } = useSampleRun(
            taskRef,
            root,
            (processedSample: Array<Record<string, null>>) => {
                if (!processedSample) return;
                setSample(processedSample);
                taskRef.value!.processedSample = processedSample;
                taskRef.value!.configuration.params.fields = Object.keys(processedSample[0]);
                currentStep.value = currentStep.value + 1;
            },
        );

        const scheduleDetailsDefined = computed(
            () =>
                taskRef.value?.configuration.retrieval.type === RetrievalType.Immediately || schedules.value.length > 0,
        );

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: canBeFinalized.value,
            },
        ]);

        const canBeFinalized = computed(
            () =>
                taskRef.value?.status !== TaskStatus.Deprecated &&
                currentStep.value === 1 &&
                scheduleDetailsDefined.value,
        );

        const harvesterLoading = computed(() => props.loading || loadingTask.value);

        const initialiseS3Storage = async () => {
            const res = await exec(ApolloAPI.createCredentials(props.task.pipeline.id));
            if (taskRef.value && res?.data) taskRef.value.configuration.connectionDetails = res.data;
        };

        const destroySecretKey = () => (taskRef.value.configuration.connectionDetails.secretKey = undefined);

        const updateSchedules = (updatedSchedules: any) => (schedules.value = updatedSchedules);

        const setSample = (sampleData: any) => (sample.value = sampleData);

        const setSampleFile = (file: TFile | null) => {
            sampleFile.value = file;
            if (!file) {
                sample.value = null;
            }
            taskRef.value!.processedSample = [];
            taskRef.value!.configuration.response.additional = [];
        };

        const setConfigurationParams = (params: any) => (taskRef.value!.configuration.params = params);

        const setRetrievalType = (retrievalType: RetrievalType) => {
            taskRef.value!.configuration.retrieval.type = retrievalType;
        };

        const uploadSampleFile = async () => {
            await uploadPolicyFiles([
                {
                    file: sampleFile.value as TFile,
                    sample: true,
                    policy: {
                        folder: 'upload',
                        subfolder: `sample/${new Date().valueOf().toString()}`,
                    },
                },
            ]);
        };

        const runOnSample = async () => {
            await save();
            await uploadSampleFile();
            executeSampleRun();
        };

        const saveTask = async () => {
            try {
                if (sample.value && taskRef.value) {
                    const arraySample = is(Array, sample.value) ? sample.value : [sample.value];
                    taskRef.value.configuration.sample = arraySample;
                }
                const shouldClearProcessedSample = await shouldClearHarvesterProcessedSample();
                await save(shouldClearProcessedSample);
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
                throw e;
            }
        };

        const finalizeTask = async () => {
            if (!canBeFinalized.value) return;

            try {
                await saveTask();

                if (taskRef.value!.configuration.fileType !== FileType.Parquet) {
                    await uploadSampleFile();
                }

                if (inDraftStatus.value && schedules.value.length) await ScheduleAPI.create(schedules.value);

                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            }
        };

        onMounted(() => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.task.pipeline?.id);
        });

        watch(
            () => props.task,
            (newTask: ApolloTask<LargeFilesHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        return {
            taskRef,
            currentStep,
            schedules,
            sample,
            sampleFile,
            scheduleDetailsDefined,
            initialiseS3Storage,
            loadingSampleRun,
            destroySecretKey,
            updateSchedules,
            setSample,
            setSampleFile,
            runOnSample,
            saveTask,
            setConfigurationParams,
            harvesterLoading,
            wizardActions,
            showFinalizeModal,
            finalizeTask,
            fileProgress,
            setRetrievalType,
        };
    },
});
